// Generic imports
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useFormik } from "formik"
import { navigate, Link } from "gatsby"
import { GoogleLogin } from "react-google-login"

// Redux imports
import * as sessionSelectors from "../../../redux/slices/session/selectors"
import * as sessionThunks from "../../../redux/slices/session/thunks"

// Utils imports
import { googleClientId } from "../../../services/utils"

// Other components imports
import Input from "../../Reusable/Input"
import Button from "../../Reusable/Button"
import CardWrapper from "../card-wrapper"
import { Styles } from "./style"
import { Row, Col } from "react-bootstrap"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {
  login: sessionThunks.login,
  loginWithGoogle: sessionThunks.loginWithGoogle,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const LoginComponent = props => {
  const { login, loginWithGoogle, user } = props
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (user.email) {
      if (user.is_tutor) {
        setTimeout(() => navigate("/dashboard/tutor"), 2000)
      } else {
        setTimeout(() => navigate("/dashboard/courses"), 2000)
      }
    }
  }, [user])

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: values => {
      setLoading(true)
      login(values).finally(() => {
        setLoading(false)
      })
    },
  })

  const responseGoogleSuccess = response => {
    console.log(response)
    loginWithGoogle({
      access_token: response.accessToken,
      id_token: response.tokenId,
    })
  }

  const responseGoogleFailed = response => {
    console.log(response)
  }

  return (
    <CardWrapper current={"login"}>
      <Styles>
        <div className="backgroundBackCard">
          <form onSubmit={formik.handleSubmit}>
            <Row>
              <Col xs={6}>
                <p className="judul-wrapper">Masuk</p>
              </Col>
              <Col xs={6}>
                <p className="daftar-wrapper">
                  {" "}
                  <Link to="/register">Daftar</Link>
                </p>
              </Col>
            </Row>
            <div className="vertical-center">
              <GoogleLogin
                className="ButtonGoogle"
                clientId={googleClientId}
                onSuccess={responseGoogleSuccess}
                onFailure={responseGoogleFailed}
                cookiePolicy={"single_host_origin"}
              >
                Masuk dengan Google
              </GoogleLogin>
            </div>

            <p className="AtauLines">
              {" "}
              <span>Atau</span>{" "}
            </p>

            <div className="input-wrapper">
              <Input
                className="InputLogin"
                type="email"
                name="email"
                placeholder="Type email"
                label="Email"
                required
                onChange={formik.handleChange}
                value={formik.values.email}
                autoFocus
              />
            </div>
            <div className="input-wrapper">
              <Input
                className="InputLogin"
                type="password"
                name="password"
                placeholder="Type password"
                label="Password"
                required
                onChange={formik.handleChange}
                value={formik.values.password}
              />
            </div>
            <Link to="/forget-password" className="lupa-password">
              Lupa password?
            </Link>
            <div className="button-wrapper">
              <Button className="Button-Login" disabled={loading} type="submit">
                Masuk
              </Button>
            </div>
          </form>
        </div>
      </Styles>
    </CardWrapper>
  )
}

export default withConnect(LoginComponent)
