import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LoginComponent from "../components/AuthComponent/Login"

const LoginPage = () => {
  return (
    <Layout noFooter>
      <SEO title="Login" />
      <LoginComponent />
    </Layout>
  )
}

export default LoginPage
